import { template as template_11ca1cc51c1c4d098127082d4da8d72c } from "@ember/template-compiler";
const FKControlMenuContainer = template_11ca1cc51c1c4d098127082d4da8d72c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
