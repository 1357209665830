import { template as template_aa735a2b309e4dac85c70c111d37af7b } from "@ember/template-compiler";
const FKLabel = template_aa735a2b309e4dac85c70c111d37af7b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
