import { template as template_2938814209b849bbbc47b70e68f217a0 } from "@ember/template-compiler";
const WelcomeHeader = template_2938814209b849bbbc47b70e68f217a0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
